import { TaskStatus, TaskStatuses } from '../../tasksTypes';

interface ShowTaskSubmitResultsTask {
  status: TaskStatus;
}

function showTaskSubmitResults(task?: ShowTaskSubmitResultsTask): boolean {
  return (
    task?.status !== TaskStatuses.DONE &&
    task?.status !== TaskStatuses.SETTING_TASK &&
    task?.status !== TaskStatuses.CANCELED
  );
}

export default showTaskSubmitResults;
